import React, { useEffect, useState } from "react";
import "./styles.css"





const ManagerMenu = () => {
    const initialState = [{id: null, name_pt:"", name_en:""}]
    const [menu, setMenu] = useState(initialState)
    const [submenu, setSubmenu] = useState([{id_menu:"", name_pt:"", name_en:""}])
    const [action, setAction] = useState("")
    const [input, setInput] = useState({name_pt:"", name_en:"", link:""});
    const [inputedit, setInputedit] = useState({id_menu:"", name_pt:"", name_en:""});
    const [inputsub, setInputsub] = useState({id_menu:"", name_pt:"", name_en:""});
    const [inputsubedit, setInputsubedit] = useState({id_menu:"", name_pt:"", name_en:""});
    const [update, setUpdate] = useState(1)
    // const [loading, setLoading] = useState(false)

    useEffect(()=>{
        fetch("https://nathivos.com.br/backend/select/u446777955_app/main_menu")
        .then(r => r.json())
        .then(r => {
            if(r.status !== "erro"){
                setMenu(r)
            }
        })

        fetch("https://nathivos.com.br/backend/select/u446777955_app/sub_menu")
        .then(r => r.json())
        .then(r => {
            if(r.status !== "erro"){
                setSubmenu(r)
            }
            // setLoading(true)
        })
    },[update])
    
    
    const salvar = ()=>{
        if (input.name_pt === "" || input.name_en === "" ) {
            alert("Faltou adicionar conteúdo")
            return
        }
        const data = new FormData()
        for (const property in input) {
            if (input[property] !== "") {
                data.append(property, input[property])
                console.log(property, " - ", input[property]);
            }
        }
        
        fetch("https://nathivos.com.br/backend/insert/u446777955_app/main_menu", {
            method: "POST",
            body: data
        })
        .then(r => r.text())
        .then(r => {
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg)
                setInput(initialState)
                setUpdate(update + 1)
                setAction("")
            }
        })
    }
    const editar = (id)=>{
        if (inputedit.name_pt === "" || inputedit.name_en === "") {
            alert("Faltou adicionar conteúdo")
            return
        }
        const data = new FormData()
        for (const property in inputedit) {
            if (inputedit[property] !== "" && inputedit[property] !== "id") {
                data.append(property, inputedit[property])
            }
        }
        
        fetch("https://nathivos.com.br/backend/update/u446777955_app/main_menu/where/id/"+id, {
            method: "POST",
            body: data
        })
        .then(r => r.json())
        .then(r => {
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg)
                setAction("")
                setUpdate(update + 1)
            }
        })
    }
    const salvarSub = ()=>{
        if (inputsub.name_pt === "" || inputsub.name_en === "" ) {
            alert("Faltou adicionar conteúdo")
            return
        }
        const data = new FormData()        
        for (const property in inputsub) {
            if (inputsub[property] !== "") {
                data.append(property, inputsub[property])
            }
        }
        
        fetch("https://nathivos.com.br/backend/insert/u446777955_app/sub_menu", {
            method: "POST",
            body: data
        })
        .then(r => r.json())
        .then(r => {
            console.log(r);
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg)
                setInputsub({name_pt:"", name_en:"", link:""})
                setAction("")
                setUpdate(update + 1)
            }
        })
    }
    const editarSub = (id)=>{
        if (inputsubedit.name_pt === "" || inputsubedit.name_en === "" ) {
            alert("Faltou adicionar conteúdo")
            return
        }
        const data = new FormData()
        for (const property in inputsubedit) {
            if (inputsubedit[property] !== "" && property !== "id" && property !== "id_menu") {
                data.append(property, inputsubedit[property])
            }
        }
        
        fetch("https://nathivos.com.br/backend/update/u446777955_app/sub_menu/where/id/"+id, {
            method: "POST",
            body: data
        })
        .then(r => r.json())
        .then(r => {
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg)
                setAction("")
                setUpdate(update + 1)
            }
        })
    }
    function apagar(id) {
        fetch("https://nathivos.com.br/backend/delete/u446777955_app/main_menu/where/id/"+id)
        .then(r => r.json())
        .then(r => {
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg)
                setAction("")
                setUpdate(update + 1)
            }
        })
    }
    function apagarSub(id) {
        fetch("https://nathivos.com.br/backend/delete/u446777955_app/sub_menu/where/id/"+id)
        .then(r => r.json())
        .then(r => {
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg)
                setAction("")
                setUpdate(update + 1)
            }
        })
    }

    return (
        <div className="ed_addcontainer">
            <div className="menu">
                {menu && menu.map(i => 
                    <div className="dropdown" key={Math.random()}>
                        <button className="dropbtn" alt="" onClick={()=>{setInputedit(i); setAction("editItem")}}>{i.name_pt}</button>
                        <div className="dropdown-content">
                            {submenu && submenu.filter((item)=>item.link_menu === i.link).map(item => 
                                <div className="dropdown_item"  onClick={()=>{setInputsubedit(item); setAction("editSubItem")}} key={Math.random()}>
                                    <span>{item.name_pt}</span>
                                </div>
                            )}
                            <div className="dropdown_item_add" onClick={()=>{setAction("addSubItem"); setInputsub({ ...inputsub, link_menu: i.link})}}>
                                <span><i className="fa fa-plus" aria-hidden="true"></i> Add sub </span>
                            </div>
                        </div>
                    </div>
                )}
                
                <div className="dropdown" >
                    <button className="dropbtn" alt="" onClick={()=>{setAction("addItem"); setInput({name_pt:"", name_en:""})}}>
                        <i className="fa fa-plus" aria-hidden="true"> Add</i>
                    </button>
                </div>
            </div>
            {action === "addItem" && <div className="formadd">
                <h3>Add novo Item de menu</h3>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Nome - Pt</label>
                    <input type="text" className="ed_input" value={input.name_pt} 
                        onChange={(e) => { setInput({ ...input, name_pt: e.target.value });}}
                    />
                </div>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Nome - En</label>
                    <input type="text" className="ed_input" value={input.name_en} 
                        onChange={(e) => { setInput({ ...input, name_en: e.target.value })}}
                    />
                </div>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Link</label>
                    <input type="text" className="ed_input" value={input.link} 
                        onChange={(e) => { setInput({ ...input, link: e.target.value })}}
                    />
                </div>
                <div className="ed_button">
                    <button className="ed_btn_salvar" onClick={() => salvar()}>Salvar</button>
                    <button className="ed_btn_cancel" onClick={() => setAction("")}>Cancelar</button>
                </div>
            </div>}
            {action === "editItem" && <div className="formadd">
                <h3>Editar Item de menu ({inputedit.name_pt})</h3>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Nome - Pt</label>
                    <input type="text" className="ed_input" value={inputedit.name_pt} 
                        onChange={(e) => { setInputedit({ ...inputedit, name_pt: e.target.value })}}
                    />
                </div>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Nome - En</label>
                    <input type="text" className="ed_input" value={inputedit.name_en} 
                        onChange={(e) => { setInputedit({ ...inputedit, name_en: e.target.value })}}
                    />
                </div>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Link</label>
                    <input type="text" className="ed_input" value={inputedit.link} 
                        onChange={(e) => { setInputedit({ ...inputedit, link: e.target.value })}}
                    />
                </div>
                <div className="ed_button">
                    <button className="ed_btn_apagar" onClick={() => apagar(inputedit.id)}>Apagar</button>
                    <button className="ed_btn_salvar" onClick={() => editar(inputedit.id)}>Salvar</button>
                    <button className="ed_btn_cancel" onClick={() => setAction("")}>Cancelar</button>
                </div>
            </div>}
            {action === "addSubItem" && <div className="formadd">
                <h3>Add novo Item ao menu {menu.name_pt}</h3>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Nome - Pt</label>
                    <input type="text" className="ed_input" value={inputsub.name_pt} 
                        onChange={(e) => { setInputsub({ ...inputsub, name_pt: e.target.value })}}
                    />
                </div>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Nome - En</label>
                    <input type="text" className="ed_input" value={inputsub.name_en} 
                        onChange={(e) => { setInputsub({ ...inputsub, name_en: e.target.value })}}
                    />
                </div>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Link</label>
                    <input type="text" className="ed_input" value={inputsub.link} 
                        onChange={(e) => { setInputsub({ ...inputsub, link: e.target.value })}}
                    />
                </div>
                <div className="ed_button">
                    <button className="ed_btn_salvar" onClick={() => salvarSub()}>Salvar</button>
                    <button className="ed_btn_cancel" onClick={() => setAction("")}>Cancelar</button>
                </div>
            </div>}
            {action === "editSubItem" && <div className="formadd">
                <h3>Editar Item do menu </h3>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Nome - Pt</label>
                    <input type="text" className="ed_input" value={inputsubedit.name_pt} 
                        onChange={(e) => { setInputsubedit({...inputsubedit, name_pt: e.target.value })}}
                    />
                </div>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Nome - En</label>
                    <input type="text" className="ed_input" value={inputsubedit.name_en} 
                        onChange={(e) => { setInputsubedit({...inputsubedit, name_en: e.target.value })}}
                    />
                </div>
                <div className="ed_input1">
                    <label htmlFor=".ed_input">Link</label>
                    <input type="text" className="ed_input" value={inputsubedit.link} 
                        onChange={(e) => { setInputsubedit({...inputsubedit, link: e.target.value })}}
                    />
                </div>
                <div className="ed_button">
                    <button className="ed_btn_apagar" onClick={() => apagarSub(inputsubedit.id)}>Apagar</button>
                    <button className="ed_btn_salvar" onClick={() => editarSub(inputsubedit.id)}>Salvar</button>
                    <button className="ed_btn_cancel" onClick={() => setAction("")}>Cancelar</button>
                </div>
            </div>}
        </div>
    )
}
export default ManagerMenu